import { TimelineContent } from '@mui/lab';
import { Grid, Paper, Typography } from '@mui/material';
import { TimelineStatus } from "adoms-common-lib";
import React from 'react';
import { timelineComponent } from '../molecule/OperationHistoryTimeline';

type FlightTimelineContentProps = {
    timelineComponent: timelineComponent
}

export const OperationHistoryTimelineContent: React.FC<FlightTimelineContentProps> = (props) => {
    const timelineOperationUser = () => {
        if (props.timelineComponent.updateUserInfo?.name) {
            return <Typography>{props.timelineComponent.updateUserInfo?.name}</Typography>
        } else if (props.timelineComponent.status === TimelineStatus.Order_Received
        ) {
            if (props.timelineComponent.newOrderInfo?.destination.lastname
                && props.timelineComponent.newOrderInfo?.destination.firstname) {
                return <Typography>
                    {props.timelineComponent.newOrderInfo?.destination.lastname + " "
                        + props.timelineComponent.newOrderInfo?.destination.firstname + " 様"}</Typography>
            };
        } else if (props.timelineComponent.status === TimelineStatus.Order_NewOrder
            || props.timelineComponent.status === TimelineStatus.Order_DepartForPort) {
            if (props.timelineComponent.newOrderInfo?.requester.lastname
                && props.timelineComponent.newOrderInfo?.requester.firstname) {
                return <Typography>
                    {props.timelineComponent.newOrderInfo?.requester.lastname + " "
                        + props.timelineComponent.newOrderInfo?.requester.firstname + " 様"}</Typography>
            };
        }
    };

    return (
        <TimelineContent>
            <Paper sx={{ wordBreak: "break-all", width: "200px" }} variant='outlined'>
                <Grid container spacing={1} direction="column" padding={1}>
                    <Grid item>
                        <Typography fontWeight="bold">{props.timelineComponent.operation}</Typography>
                    </Grid>
                    <Grid item>{timelineOperationUser()}</Grid>
                </Grid>
            </Paper>
        </TimelineContent>
    )
};