import { Backdrop, CircularProgress, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent } from '@mui/material';
import { blue } from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from 'tss-react/mui';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { Alert } from '@mui/material';
import { createInitData, Display, MemberType, OrderInfo, Port } from 'adoms-common-lib';
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ScanQrCode, sejQRCodeData } from '../../components/atoms/ScanQrCodeVideo';
import ApplicationTooBar from '../../components/molecule/ApplicationToolBar';
import OrderStepBar, { Process } from '../../components/molecule/OrderStepBar';
import { APIConnector } from '../../connector/APIConnector';
import { BlackStyledButton } from '../../components/atoms/blackStyledButton';
import { CustomerURLInformationUtil } from '../../common/customerURLInformationUtil';

const useStyles = makeStyles()((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  paper: {
    display: 'flex',
    padding: '12px',
    textAlign: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    verticalAlign: 'middle',
  },
  select: {
    width: 200,
  },
  griditem: {
  },

  link: {
    textDecoration: 'none',
  },
  avatar: {
    backgroundColor: blue[500],
  },

  title: {
    flexGrow: 1,
    textAlign: 'center',
    backgroundColor: blue[500],
    color: 'white',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  alert: {
    marginBottom: theme.spacing(2),
    textAlign: "left"
  },
  qrResult: {
    marginBottom: theme.spacing(2),
    textAlign: "left"
  }
}));


/**
 * QRコード読み取り、フライト希望日時・配送区間指定画面
 */
export default function OrderFlightSearchInput(props: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const customerURLInformation = CustomerURLInformationUtil.instance.getCustomerURLInformation();
  let orderForEdit = location.state?.orderForEdit as OrderInfo;
  const [order, setOrder] = React.useState<OrderInfo>(orderForEdit ? orderForEdit : createInitData());
  const { classes } = useStyles();
  const [prefDeliveryDate, setPrefDeliveryDate] = React.useState(orderForEdit?.preferredDeliveryDate ? orderForEdit.preferredDeliveryDate : Display.getNowDateWithString());
  const [isPrefDeliveryDateValid, setIsPrefDeliveryDateValid] = React.useState<boolean>(Display.checkPrefDeliveryDate(prefDeliveryDate));
  const [partner, setPartner] = React.useState(orderForEdit?.businessPartnerId ? orderForEdit.businessPartnerId : '');
  const [departure, setDeparture] = React.useState(orderForEdit?.departure?.id ? orderForEdit.departure.id : '');
  const [arrival, setArrival] = React.useState(orderForEdit?.arrival?.id ? orderForEdit.arrival.id : '');
  const [prefDeliveryTime, setPrefDeliveryTime] = React.useState(orderForEdit?.preferredDeliveryTime ? orderForEdit.preferredDeliveryTime : "指定なし");

  const [partnerList, setPartnerList] = React.useState([] as string[]);
  const [departurePortList, setDeparturePortList] = React.useState([] as Port[])
  const [allPortList, setAllPortList] = React.useState([] as Port[])
  const [arrivalPortList, setArrivalPortList] = React.useState([] as Port[]);
  //次へボタンをdiableにするかどうかのフラグ
  const [isNextButtonDisableFlag, setNextButtonDisableFlag] = React.useState(true);
  const [isDisplayLoadingMark, setDisplayLoadingMark] = React.useState<boolean>(false);
  const [qrCodeErrorList, setQrCodeErrorList] = React.useState<string[]>(location.state?.qrCodeErrorList ? location.state?.qrCodeErrorList : []);
  const [sejQrCodeJsonData, setSejQrCodeJsonData] = React.useState<sejQRCodeData | undefined>(undefined);
  const [qrCodeJsonData, setQrCodeJsonData] = React.useState<Object | undefined>(undefined);
  const [qrCodeData, setQrCodeData] = React.useState<string | undefined>(undefined);
  const [isScanning, setScanning] = React.useState(false);
  const [displayQrCodeScanResult, setDisplayQrCodeScanResult] = React.useState<string | undefined>(location.state?.displayQrCodeScanResult ? location.state?.displayQrCodeScanResult : undefined);

  /**
   * 次へボタンを有効かするかどうかの判断をする。
   * @returns trueの場合無効、falseの場合有効
   */
  const isNextButtonDisable = () => {
    if (
      partner === "" ||
      departure === "" ||
      arrival === "" ||
      !isPrefDeliveryDateValid
    ) {
      return true;
    } else {
      return false;
    }
  }

  //ポート情報を取得
  useEffect(() => {
    const fetchData = async () => {
      try {
        setDisplayLoadingMark(true);
        if (customerURLInformation == null) {
          navigate("/forbidden403Page");
        }
        console.log(customerURLInformation);
        let urlId = customerURLInformation!!.urlId;
        let c: APIConnector = APIConnector.instance;
        let portList = await c.getPortListForCustomer(customerURLInformation!!.businessPartnerId, customerURLInformation!!.tenantId);
        //すべてのポートを設定
        setAllPortList(portList);
        let partnerArray = [] as string[];
        portList.forEach(port => {
          if (!partnerArray.includes(port.businessPartnerId)) {
            partnerArray.push(port.businessPartnerId);
          }
        });
        setPartnerList(partnerArray);
        let depPortList: Port[] = [];
        let arrPortList: Port[] = [];
        if (!partner) {
          // デフォルトで順位が上のパートナーIDを設定
          setPartner(partnerArray[0]);
          depPortList = portList.filter((v) => {
            return v.businessPartnerId === partnerArray[0];
          });

          arrPortList = portList.filter((v) => {
            if (v.businessPartnerId === partnerArray[0]) {
              return depPortList[0].destinationPortList.includes(v.id);
            } else {
              return false;
            }
          });
          // デフォルトで順位が上の出発ポートを設定
          setDeparture(depPortList[0].id);

        } else {
          depPortList = portList.filter((v) => {
            return v.businessPartnerId === partner;
          });

          arrPortList = portList.filter((v) => {
            if (v.businessPartnerId === partner) {
              return depPortList[0].destinationPortList.includes(v.id);
            } else {
              return false;
            }
          });
        }
        //パートナーIDにあった出発ポート設定
        setDeparturePortList(depPortList);
        // デフォルトで出発ポートに合った到着ポートリストを設定
        setArrivalPortList(arrPortList);

        setDisplayLoadingMark(false);
      } catch (error) {
        setDisplayLoadingMark(false);
        console.error(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    setNextButtonDisableFlag(isNextButtonDisable());
  }, [prefDeliveryDate, partner, arrival, prefDeliveryTime, isNextButtonDisableFlag]);

  /**
   * パートナーが変更されたときのハンドラ
   * @param event 
   */
  const handleChangePartner = (event: any) => {
    setPartner(event.target.value);
    //パートナーが変更された場合、出発地と到着地をクリアする
    setArrival("");
    setDeparture("");

    const depPortList = allPortList.filter((v) => {
      return v.businessPartnerId === event.target.value;
    });
    //出発ポート設定
    setDeparturePortList(depPortList);

    // デフォルトで順位が上の出発ポートを設定
    setDeparture(depPortList[0].id);

    //到着ポートを設定
    const arrPortList = allPortList.filter((v) => {
      if (v.businessPartnerId === depPortList[0].businessPartnerId) {
        return depPortList[0].destinationPortList.includes(v.id);
      } else {
        return false;
      }
    });
    // デフォルトで出発ポートに合った到着ポートリストを設定
    setArrivalPortList(arrPortList);
  };

  /**
   * 出発地が変更されたときのハンドラ
   * @param event 
   */
  const handleChangeDeparture = (event: any) => {
    setDeparture(event.target.value);
    //出発地が変更された場合、到着地をクリアする
    setArrival("");

    const depPort = allPortList.find(v => {
      return v.id === event.target.value;
    }) as Port;

    const arrPortList = allPortList.filter((v) => {
      if (v.businessPartnerId === partner) {
        return depPort.destinationPortList.includes(v.id);
      } else {
        return false;
      }
    });
    //到着ポートを設定
    setArrivalPortList(arrPortList);
  };

  /**
   * 到着地が変わった時のハンドラ
   * @param event 
   */
  const handleChangeArrival = (event: any) => {
    setArrival(event.target.value);
  };



  /**
   * 配送日が変わった時のハンドラ
   * @param event 
   */
  const handleChangeFlightDate = (event: any) => {
    //日付のバリデーションチェック
    setIsPrefDeliveryDateValid(Display.checkPrefDeliveryDate(event.target.value));
    //入力を反映させるため結果に関わらず日付をセット
    setPrefDeliveryDate(event.target.value);
  };

  /**
   * 配送時刻のハンドラ
   * @param event 
   */
  const handleChangeTimeBucket = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
    setPrefDeliveryTime(event.target.value as string);
  }


  /**
   * 次のページに遷移する際の処理
   * @param event 
   */
  const handleToNextPage = (event: any, order: OrderInfo) => {
    setNextButtonDisableFlag(true);
    const fetchData = async () => {
      let c: APIConnector = APIConnector.instance;
      let portList: Promise<void | Port[]> = c.getPortListForCustomer(customerURLInformation!!.businessPartnerId, customerURLInformation!!.tenantId);
      portList.then((value) => {
        //このハンドラで便をフェッチする。
        // order.flight = createDefault();
        // order.flight.flightDate = getJSTToString(flightDate);
        order.departure = allPortList.find((val) => { return departure === val.id }) as Port;
        order.arrival = allPortList.find((val) => { return arrival === val.id }) as Port;
        order.businessPartnerId = partner;
        order.preferredDeliveryDate = prefDeliveryDate;
        order.preferredDeliveryTime = prefDeliveryTime;

        order.requester = {
          id: "empty",
          address: "",
          city: "",
          prefecture: "",
          zipcode: "",
          firstname: orderForEdit?.requester?.firstname ? orderForEdit.requester.firstname : "",
          lastname: orderForEdit?.requester?.lastname ? orderForEdit.requester.lastname : "",
          type: MemberType.Guest,
          tel: "",
        }
        setNextButtonDisableFlag(false);
        if (orderForEdit) {
          // 配送情報修正のために本ページを開いた場合

          // QRコードで読み取った情報
          navigate(
            "/ordergoodsanddestinationinput/" + customerURLInformation?.urlId,
            {
              state: {
                orderForEdit: order,
                displayQrCodeScanResult: displayQrCodeScanResult,
                qrCodeErrorList: qrCodeErrorList
              },
            }
          );
        } else if (qrCodeErrorList.length !== 0) {
          // QRコードで読み取った情報
          if (displayQrCodeScanResult) {
            order.remarks = displayQrCodeScanResult;
          };
          navigate(
            "/ordergoodsanddestinationinput/" + customerURLInformation?.urlId,
            {
              state: {
                orderForEdit: order,
                displayQrCodeScanResult: displayQrCodeScanResult,
                qrCodeErrorList: qrCodeErrorList
              },
            }
          )
        } else {
          navigate(
            "/ordergoodsanddestinationinput/" + customerURLInformation?.urlId,
            {
              state: {
                order: order
              },
            }
          );
        };
      });
    }
    fetchData();
  }

  const getTimeBucket = () => {
    const startHour = 10;
    const startMinute = 0;
    const endHour = 17;
    const endMinute = 30;
    const intervalMinutes = 30;

    const startDateTime = new Date();
    startDateTime.setHours(startHour);
    startDateTime.setMinutes(startMinute);
    startDateTime.setSeconds(0);

    const endDateTime = new Date();
    endDateTime.setTime(startDateTime.getTime());
    endDateTime.setHours(endHour);
    endDateTime.setMinutes(endMinute);
    endDateTime.setSeconds(0);

    let timeBucketList = ["指定なし"];
    const startDateTimeForCompare = new Date();
    startDateTimeForCompare.setTime(startDateTime.getTime());
    startDateTimeForCompare.setMinutes(startDateTime.getMinutes() + intervalMinutes);

    while (startDateTimeForCompare <= endDateTime) {
      const startTimeBucket =
        startDateTime.getHours().toString().padStart(2, '0')
        + ":"
        + startDateTime.getMinutes().toString().padStart(2, '0');
      startDateTime.setMinutes(startDateTime.getMinutes() + intervalMinutes);
      const endTimeBucket =
        startDateTime.getHours().toString().padStart(2, '0')
        + ":"
        + startDateTime.getMinutes().toString().padStart(2, '0');

      timeBucketList.push(startTimeBucket + "-" + endTimeBucket);
      startDateTimeForCompare.setMinutes(startDateTimeForCompare.getMinutes() + intervalMinutes);
    };

    return timeBucketList;

    // return [
    //   '指定なし',
    //   '12:00-12:15',
    //   '12:15-12:30',
    //   '12:30-12:45',
    //   '12:45-13:00',
    //   '13:00-13:15',
    //   '13:15-13:30',
    //   '13:30-13:45',
    //   '13:45-14:00',
    //   '14:00-14:15',
    //   '14:15-14:30',
    //   '14:30-14:45',
    //   '14:45-15:00',
    //   '15:00-15:15',
    //   '15:15-15:30',
    //   '15:30-15:45',
    //   '15:45-16:00',
    //   '16:00-16:15',
    //   '16:15-16:30',
    //   '16:30-16:45',
    //   '16:45-17:00',
    //   '17:00-17:15',
    //   '17:15-17:30',
    //   '17:30-17:45',
    //   '17:45-18:00',
    //   '18:00-18:15',
    //   '18:15-18:30',
    //   '18:30-18:45',
    //   '18:45-19:00',
    //   '19:00-19:15',
    //   '19:15-19:30',
    //   '19:30-19:45',
    //   '19:45-20:00',
    // ]
  };

  /**
   * QRコード読み取り後に配送情報を作成する
   */
  useEffect(() => {
    if (qrCodeJsonData || sejQrCodeJsonData) {

      // QRコード読み取りの場合（配送情報修正ではない場合）
      let qrCodeErrorListParam = [];
      let orderParam = createInitData();
      const SEJ_PARTNER_ID = "SEJ";
      const SEJ_DEPARTURE_PORT_ID = "SEJFUK01";

      orderParam.businessPartnerId = SEJ_PARTNER_ID;
      orderParam.departure = allPortList.find((val) => { return SEJ_DEPARTURE_PORT_ID === val.id }) as Port;
      orderParam.goods = [{ weight: 0, name: '荷物一式', count: 1 }];
      orderParam.destination.type = MemberType.Guest;
      orderParam.destination.id = "empty";
      orderParam.destination.address = "";
      orderParam.destination.zipcode = "";
      orderParam.destination.prefecture = "";
      orderParam.destination.tel = "***";
      orderParam.destination.tel2 = "***";
      orderParam.requester = {
        id: "empty",
        address: "",
        city: "",
        prefecture: "",
        zipcode: "",
        firstname: "",
        lastname: "",
        type: MemberType.Guest,
        tel: "",
      };

      let qrCodeJsonMap
      if (qrCodeJsonData) {
        // QRコードで読み取ったデータがsejQRCodeData型に変換できなかった場合
        qrCodeJsonMap = new Map(Object.entries(qrCodeJsonData));
      };

      /**
       * 配送希望日時
       */
      try {
        let time: string | undefined
        if (sejQrCodeJsonData?.Time) {
          time = sejQrCodeJsonData.Time;
        } else if (qrCodeJsonMap?.has("Time")) {
          time = qrCodeJsonMap.get("Time");
        } else {
          throw new Error("QRコード読み取り結果にTimeが存在しません");
        };

        if (time) {
          const preferredDeliveryDateTime = getPreferredDeliveryDateTime(time);

          // 配送希望日
          const preferredDeliveryDateParam = preferredDeliveryDateTime?.preferredDeliveryDate;
          setPrefDeliveryDate(preferredDeliveryDateParam);
          orderParam.preferredDeliveryDate = preferredDeliveryDateParam;
          // 配送希望時間
          const preferredDeliveryTimeParam = preferredDeliveryDateTime?.preferredDeliveryTime;
          setPrefDeliveryTime(preferredDeliveryTimeParam);
          orderParam.preferredDeliveryTime = preferredDeliveryTimeParam;
        } else {
          throw new Error("Timeが取得できませんでした");
        };
      } catch (error) {
        setPrefDeliveryDate("");
        setPrefDeliveryTime("");
        setIsPrefDeliveryDateValid(false);
        console.error(JSON.stringify(error));
        let err = error as Error;
        qrCodeErrorListParam.push(err.message);
        setQrCodeErrorList(qrCodeErrorListParam);
      };

      /**
       * 到着ポート
       */
      try {
        let arrivalPort: string | undefined
        if (sejQrCodeJsonData?.Port) {
          arrivalPort = sejQrCodeJsonData.Port;
        } else if (qrCodeJsonMap?.has("Port")) {
          arrivalPort = qrCodeJsonMap.get("Port");
        } else {
          throw new Error("QRコード読み取り結果にPortが存在しません");
        };

        if (arrivalPort) {
          const arrivalParam = getArrivalPort(arrivalPort);
          orderParam.arrival = arrivalParam;
          setArrival(arrivalParam.id);
        } else {
          throw new Error("Portが取得できませんでした");
        };
      } catch (error) {
        setArrival("");
        console.error(JSON.stringify(error));
        let err = error as Error;
        qrCodeErrorListParam.push(err.message);
        setQrCodeErrorList(qrCodeErrorListParam);
      };


      /**
       * 氏名
       */
      try {
        let name: string | undefined
        if (sejQrCodeJsonData?.Name) {
          name = sejQrCodeJsonData.Name;
        } else if (qrCodeJsonMap?.has("Name")) {
          name = qrCodeJsonMap.get("Name");
        } else {
          throw new Error("QRコード読み取り結果にNameが存在しません");
        };

        if (name) {
          const nameArray = getName(name);
          orderParam.destination.firstname = nameArray[1];
          orderParam.destination.lastname = nameArray[0];
        } else {
          throw new Error("Nameが取得できませんでした");
        };
      } catch (error) {
        orderParam.destination.firstname = "";
        orderParam.destination.lastname = "";
        console.error(JSON.stringify(error));
        let err = error as Error;
        qrCodeErrorListParam.push(err.message);
        setQrCodeErrorList(qrCodeErrorListParam);
      };

      /**
       * リマークス
       */
      if (displayQrCodeScanResult) {
        orderParam.remarks = displayQrCodeScanResult;
      };

      console.log(JSON.stringify(orderParam, null, 2));
      setOrder(orderParam);
      if (qrCodeErrorListParam.length === 0) {
        navigate(
          "/orderconfirm/" + customerURLInformation?.urlId,
          {
            state: {
              order: orderParam,
              displayQrCodeScanResult: displayQrCodeScanResult,
              imageBase64: undefined,
              imageUrl: undefined
            }
          });
      };
    } else if (qrCodeData) {
      setPrefDeliveryDate("");
      setPrefDeliveryTime("");
      setIsPrefDeliveryDateValid(false);
      setArrival("");
      let orderParam = createInitData();
      orderParam.remarks = qrCodeData;
      setOrder(orderParam);
    };
  }, [displayQrCodeScanResult]);

  /**
   * 配送希望日時を取得する
   * @param time 
   * @returns preferredDeliveryDate:配送希望日 preferredDeliveryTime:配送希望時間
   */
  const getPreferredDeliveryDateTime = (time: string): {
    preferredDeliveryDate: string,
    preferredDeliveryTime: string
  } => {

    const datePattern = "(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])"
    const timePattern = "([01][0-9]|2[0-3]):[0-5][0-9]";
    // MM/DD HH:MM-HH:MM
    const sejTimePattern = "^" + datePattern + " " + timePattern + "-" + timePattern + "$";

    if (!RegExp(sejTimePattern).test(time)) {
      throw new Error("Timeは「MM/DD HH:MM-HH:MM」の形式で入力してください");
    };

    const year = new Date().getFullYear();
    return ({
      preferredDeliveryDate: year + "-" + time.substring(0, 2) + "-" + time.substring(3, 5),
      preferredDeliveryTime: time.substring(6, 17)
    });
  };

  /**
   * 到着ポートを取得する
   * @param arrivalPort 
   * @returns Port
   */
  const getArrivalPort = (arrivalPort: string): Port => {
    // 能古島公民館グラウンド
    const PatternA = /A|能古|公民館/;
    const PatternB = /B|渡船場|駐車場/;
    const PatternC = /C|江ノ口/;
    const PatternD = /D|西町/;
    const PatternE = /E|清和園/;
    if (arrivalPort.match(PatternA)) {
      return allPortList.find((val) => { return "A" === val.shortName }) as Port
    } else if (arrivalPort.match(PatternB)) {
      return allPortList.find((val) => { return "B" === val.shortName }) as Port
    } else if (arrivalPort.match(PatternC)) {
      return allPortList.find((val) => { return "C" === val.shortName }) as Port
    } else if (arrivalPort.match(PatternD)) {
      return allPortList.find((val) => { return "D" === val.shortName }) as Port
    } else if (arrivalPort.match(PatternE)) {
      return allPortList.find((val) => { return "E" === val.shortName }) as Port
    } else {
      throw new Error("対象の到着ポートが見つかりませんでした。Portを確認してください。Port:" + arrivalPort);
    };
  };

  /**
  * 氏名を取得する
  * @param name 
  * @returns Port
  */
  const getName = (name: string): string[] => {
    const nameArray = name.split("　");
    if (nameArray.length !== 2) {
      throw new Error("Nameは「姓　名」の形式で入力してください");
    };
    return nameArray;
  };

  return (
    <div className="App">
      <Grid container justifyContent="center" item xs={12} spacing={1}>
        <Backdrop className={classes.backdrop} open={isDisplayLoadingMark}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid item xs={12} sm={12} >
          <ApplicationTooBar label="配送依頼" />
        </Grid>
        <Grid item xs={12} sm={12} >
          <OrderStepBar process={Process.FlightSelect} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
            配送情報QRコード読み取り
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} className={classes.griditem}>
          <Paper className={classes.paper} elevation={0}>
            <Grid item xs={12} sm={12}>
              {qrCodeErrorList.map((qrCodeError, index) => (
                <Alert
                  key={index}
                  severity="error"
                  className={classes.alert}>{qrCodeError}</Alert>
              ))}
              {displayQrCodeScanResult ?
                <Alert severity="success"
                  className={classes.qrResult}>{"QRコード読み取り結果"}<br />{displayQrCodeScanResult}</Alert>
                : undefined
              }
            </Grid>
            {isScanning ?
              <Grid item xs={12} sm={12}>
                <ScanQrCode
                  setScan={setScanning}
                  isScan={isScanning}
                  setQrCodeErrorList={setQrCodeErrorList}
                  setQrCodeData={setQrCodeData}
                  setQrCodeJsonData={setQrCodeJsonData}
                  setSejQrCodeJsonData={setSejQrCodeJsonData}
                  setDisplayQrCodeScanResult={setDisplayQrCodeScanResult} />
              </Grid>
              :
              <Grid item xs={12} sm={12}>
                <BlackStyledButton
                  startIcon={<PhotoCamera />}
                  variant="outlined"
                  onClick={() => setScanning(true)}
                >
                  カメラを起動
                </BlackStyledButton>
              </Grid>
            }
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
            フライト希望日時
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} className={classes.griditem}>
          <Paper className={classes.paper} elevation={0}>
            <FormControl className={classes.formControl}>
              <TextField
                variant="standard"
                error={!isPrefDeliveryDateValid}
                id="date"
                label="フライト日（当日以降）"
                type="date"
                value={prefDeliveryDate}
                className={classes.textField}
                onChange={handleChangeFlightDate}
                InputLabelProps={{
                  shrink: true,
                }} />
            </FormControl>
            <FormControl
              className={classes.formControl}
              error={!prefDeliveryTime}>
              <InputLabel id="demo-simple-select-outlined-label">フライト時間</InputLabel>
              <Select
                variant="standard"
                className={classes.select}
                labelId="prefDeliveryTime"
                id="prefDeliveryTime"
                value={prefDeliveryTime}
                onChange={handleChangeTimeBucket}
                label="フライト時間">
                {getTimeBucket().map((bucket) =>
                  <MenuItem key={bucket} value={bucket}>{bucket}</MenuItem>
                )}
              </Select>
            </FormControl>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={12} >
          <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
            配送区間指定
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} className={classes.griditem}>
          <Paper className={classes.paper} elevation={0}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">出発</InputLabel>
              <Select
                variant="standard"
                className={classes.select}
                labelId="dep"
                id="dep"
                value={departure}
                onChange={handleChangeDeparture}
                label="出発">
                {Array.from(departurePortList.values()).map((port, i) => (
                  <MenuItem key={port.id} value={port.id}>{port.name}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">到着</InputLabel>
              <Select
                variant="standard"
                className={classes.select}
                labelId="arv"
                id="arv"
                value={arrival}
                onChange={handleChangeArrival}
                label="到着">
                {Array.from(arrivalPortList.values()).map((port, i) =>
                  <MenuItem key={port.id} value={port.id}>{port.name}</MenuItem>
                )}
              </Select>
            </FormControl>
          </Paper>
        </Grid>

        <Grid item xs={6} sm={6} className={classes.griditem}>
          <Paper className={classes.paper} elevation={0} onClick={() => navigate(-1)}>
            <FormControl variant="standard" className={classes.formControl}>
              <IconButton edge="start" color="primary" aria-label="menu" size="large">
                <ArrowBackIosIcon /><Typography>戻る</Typography>
              </IconButton>
            </FormControl>
          </Paper>
        </Grid>
        <Grid item xs={6} sm={6} className={classes.griditem} >
          <Paper className={classes.paper} elevation={0}  >
            <FormControl variant="standard" className={classes.formControl}>
              <IconButton
                edge="start"
                color="primary"
                aria-label="menu"
                disabled={isNextButtonDisableFlag}
                onClick={(e) => { handleToNextPage(e, order) }}
                size="large">
                <Typography>次へ</Typography><ArrowForwardIosIcon />
              </IconButton>
            </FormControl>
          </Paper>
        </Grid>
      </Grid>
    </div >
  );
}