import NotesIcon from '@mui/icons-material/Notes';
import { Alert, Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import { OrderInfo } from "adoms-common-lib";
import React, { useEffect } from 'react';
import { APIConnector } from '../../connector/APIConnector';
import { LoadingMark } from '../atoms/LoadingMark';

type EditDialogProps = {
    order?: OrderInfo
    setOrder: React.Dispatch<React.SetStateAction<OrderInfo | undefined>>
    tenantID: string
    isDisplayOrderEditDialog: boolean
    setDisplayOrderEditDialog: React.Dispatch<React.SetStateAction<boolean>>
};

export const EditDialog: React.FC<EditDialogProps> = (props) => {
    const [remarks, setRemarks] = React.useState<string>(props.order?.remarks ?? "");
    const [orderRemarksError, setOrderRemarksError] = React.useState<string>();
    const [isDisplayLoadingMark, setDisplayLoadingMark] = React.useState<boolean>(false);

    useEffect(() => {
        setRemarks(props.order?.remarks ?? "");
    }, [props.isDisplayOrderEditDialog]);

    const handleChangeRemarks = async () => {
        let c: APIConnector = APIConnector.instance;
        if (props.order && props.tenantID) {
            const requestOrder = structuredClone(props.order);
            requestOrder.remarks = remarks;
            setDisplayLoadingMark(true);
            await c.putOrderChangeForCustomer(requestOrder, props.tenantID).then((orderInfo) => {
                setDisplayLoadingMark(false);
                setOrderRemarksError(undefined);
                props.setOrder(orderInfo);
                props.setDisplayOrderEditDialog(false);
            }).catch((error) => {
                console.log(error);
                setDisplayLoadingMark(false);
                setOrderRemarksError("備考が更新できませんでした。");
            });
        };
    };

    return (
        <Dialog
            open={props.isDisplayOrderEditDialog}
            onClose={(e) => props.setDisplayOrderEditDialog(false)}>
            <DialogTitle id="dialog-title">
                配送情報編集
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    {orderRemarksError ?
                        <Grid item>
                            <Alert severity="error">{orderRemarksError}</Alert>
                        </Grid> : undefined
                    }
                    {props.order?.orderID ?
                        <Grid container item spacing={2}>
                            <Grid item>
                                <Avatar sx={{ bgcolor: blue[400], width: 30, height: 30 }}>
                                    <NotesIcon fontSize='small' />
                                </Avatar>
                            </Grid>
                            <Grid item xs container direction="column" >
                                <Grid item>
                                    <Typography variant="caption" component="div" sx={{ color: grey[700] }}>
                                        備考
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        value={remarks}
                                        onChange={event => { setRemarks(event.target.value) }}
                                        multiline
                                        rows={2}
                                        maxRows="4"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        : undefined}
                </Grid>
                <LoadingMark isDisplayLoadingMark={isDisplayLoadingMark} />
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    disabled={remarks === props.order?.remarks || isDisplayLoadingMark}
                    onClick={handleChangeRemarks}>
                    登録
                </Button>
            </DialogActions>
        </Dialog>
    )
}