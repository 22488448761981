import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { Avatar, Grid, Paper, Typography } from '@mui/material';
import { blue, grey } from '@mui/material/colors';
import { OrderInfo } from 'adoms-common-lib';
import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import NotesIcon from '@mui/icons-material/Notes';

type OrderInfoDisplayPaperProps = {
    order?: OrderInfo
    setDisplayQRCode: React.Dispatch<React.SetStateAction<boolean>>
    setDisplayOrderEditDialog: React.Dispatch<React.SetStateAction<boolean>>
    customerInfoDisplayFlg: boolean
};

/**
 * 配送情報
 * @param props order
 */
export const OrderInfoDisplayPaper: React.FC<OrderInfoDisplayPaperProps> = (props) => {
    return (
        <Paper sx={{
            borderRadius: "10px",
            margin: "0px 10px",
            padding: "10px"
        }} elevation={2}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Avatar sx={{ bgcolor: blue[400], width: 36, height: 36 }}>
                                <ConfirmationNumberIcon fontSize='small' />
                            </Avatar>
                        </Grid>
                        <Grid item xs container direction="column" >
                            <Grid item>
                                <Typography variant="caption" component="div" sx={{ color: grey[700] }}>
                                    確認番号
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="h6" component="div" sx={{ color: "#2196f3" }} fontWeight={"bold"}>
                                    {props.order?.orderID}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <EditIcon
                                fontSize='small'
                                onClick={(e) => props.setDisplayOrderEditDialog(true)}
                                sx={{ color: grey[700], marginTop: "4px" }} />
                        </Grid>
                        <Grid item>
                            <QrCodeIcon
                                fontSize='small'
                                onClick={(e) => props.setDisplayQRCode(true)}
                                sx={{ color: grey[700], marginTop: "4px" }} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Avatar sx={{ bgcolor: blue[400], width: 36, height: 36 }}>
                                <FlightTakeoffIcon fontSize='small' />
                            </Avatar>
                        </Grid>
                        <Grid item xs container direction="column" >
                            <Grid item>
                                <Typography variant="caption" component="div" sx={{ color: grey[700] }}>
                                    出発地
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle1" component="div" fontWeight={"bold"}>
                                    {props.order?.departure.name}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Avatar sx={{ bgcolor: blue[400], width: 36, height: 36 }}>
                                <FlightLandIcon fontSize='small' />
                            </Avatar>
                        </Grid>
                        <Grid item xs container direction="column" >
                            <Grid item>
                                <Typography variant="caption" component="div" sx={{ color: grey[700] }}>
                                    到着地
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle1" component="div" fontWeight={"bold"}>
                                    {props.order?.arrival.name}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Avatar sx={{ bgcolor: blue[400], width: 36, height: 36 }}>
                                <NotesIcon fontSize='small' />
                            </Avatar>
                        </Grid>
                        <Grid item xs container direction="column" >
                            <Grid item>
                                <Typography variant="caption" component="div" sx={{ color: grey[700] }}>
                                    備考
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle1" component="div" fontWeight={"bold"}>
                                    {props.order ? (props.order.remarks && props.order.remarks !== "" ? props.order?.remarks : "設定なし") : undefined}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {props.customerInfoDisplayFlg ?
                    <Grid item xs={12} sm={12}>
                        <Grid container spacing={2}>
                            <Grid item>
                                <Avatar sx={{ bgcolor: blue[400], width: 36, height: 36 }}>
                                    <PersonIcon fontSize='small' />
                                </Avatar>
                            </Grid>
                            <Grid item xs container direction="column" >
                                <Grid item>
                                    <Typography variant="caption" component="div" sx={{ color: grey[700] }}>
                                        お客様情報
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle1" component="div" fontWeight={"bold"}>
                                        {props.order?.destination.lastname} {props.order?.destination.firstname}様
                                    </Typography>
                                    <Typography variant="subtitle2" component="div">
                                        〒{props.order?.destination.zipcode}
                                    </Typography>
                                    <Typography variant="subtitle2" component="div">
                                        {props.order?.destination.prefecture}{props.order?.destination.address}
                                    </Typography>
                                    <Grid container direction="row" alignItems={"center"}>
                                        <Grid item>
                                            <PhoneIcon fontSize='small' sx={{ marginTop: "4px" }} />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle2" component="div">
                                                {props.order?.destination.tel ? props.order?.destination.tel : "設定なし"}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    : undefined}
            </Grid>
        </Paper>
    )
}