import { FeatureCollection, GeoJsonProperties, Geometry } from "geojson";
import { Dayjs, extend } from 'dayjs';
import duration, { Duration } from 'dayjs/plugin/duration';
extend(duration);

export interface WeatherInfoRequest {
    // 表示時間
    displayDateTimeJST: Dayjs,
    // 海面高度
    altitude: string;
    // 表示範囲の緯度経度
    latLonRange: LatLonRange,
    // メッシュサイズ
    grid?: string
};

export interface LatLonRange {
    latStart: string,
    latEnd: string,
    lonStart: string,
    lonEnd: string
};

export interface WeatherGeoJson {
    validtime_minutes: string,
    basetime: string,
    latStart_latEnd_lonStart_lonEnd: string,
    altitude: string,
    geoJson: FeatureCollection<Geometry, WeatherFeaturesProperty>
};

export interface WaypointWeatherGeoJson {
    kmlFileName: string,
    validtime_minutes: string,
    basetime: string,
    altitudeLowerLimit: string,
    altitudeUpperLimit: string,
    geoJsonList: {
        altitude: string,
        featureCollection: FeatureCollection<Geometry, WeatherFeaturesProperty>
    }[],
    flightTimeInformationList: FlightTimeInformation[]
};

export interface WaypoinWindRequest {
    displayDateJST_YYYYMMDD: string;
    displayTimeJST_HHMM: string;
    businessPartnerId: string;
    departurePortId: string;
    arrivalPortId: string;
    altitudeLowerLimit: number;
    altitudeUpperLimit: number;
    grid: string;
};

export interface FlightTimeInformation {
    fromWaypointIndex: number;
    toWaypointIndex: number;
    distance_m: number;
    course_deg: number;
    wca_deg: number;
    gs_msec: number;
    tas_msec: number;
    tasflightTimeTotalSec: number;
    gsFlightTimeTotalSec: number;
    tasFlightTime_hhmmss: string;
    gsFlightTime_hhmmss: string;
};

export type WeatherFeaturesProperty = GeoJsonProperties & {
    ugrd?: number,
    vgrd?: number,
    windSpeed?: number,
    windDirection?: number,
    rain?: number,
    grid?: string,
    altitude?: string,
    waypointIndex?: number
};

export type adustEtaDuration = {
    takeoffDuration1: Duration,
    landingDuration1: Duration,
    takeoffDuration2: Duration,
    landingDuration2: Duration,
    transitDuration: Duration
}