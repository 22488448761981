import CancelIcon from '@mui/icons-material/Cancel';
import DoneIcon from '@mui/icons-material/Done';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import { Timeline, TimelineConnector, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@mui/lab';
import { Alert, CircularProgress, Grid, SvgIcon, Typography } from '@mui/material';
import { AssosiateFlightAndOrder, EventName, FlightInfo, OperationHistory, OperationHistoryUtil, OrderInfo, RecordType, TimelineStatus, UserInfo } from "adoms-common-lib";
import React, { useEffect } from 'react';
import { ReactComponent as CartCheckIcon } from "../../asset/icons/cart-check.svg";
import { ReactComponent as PackageCheckedIcon } from "../../asset/icons/package-variant-closed-check.svg";
import { ReactComponent as PackageIcon } from "../../asset/icons/package-variant-closed.svg";
import { ReactComponent as StoreClockIcon } from "../../asset/icons/store-clock.svg";
import { APIConnector } from "../../connector/APIConnector";
import { OperationHistoryTimelineContent } from '../atoms/OperationHistoryTimelineContent';

type OperationHistoryTimelineProps = {
    tenantId?: string,
    order?: OrderInfo
}

export type timelineComponent = {
    date: string,
    time: string,
    status: TimelineStatus,
    operation: string,
    recordType?: RecordType,
    changedColumn?: string,
    assosiateFlightAndOrder?: AssosiateFlightAndOrder,
    newFlightInfo?: FlightInfo,
    oldFlightInfo?: FlightInfo,
    newOrderInfo?: OrderInfo,
    oldOrderInfo?: OrderInfo,
    updateUserInfo?: UserInfo
}

export const OperationHistoryTimeline: React.FC<OperationHistoryTimelineProps> = (props) => {
    const [timelineComponentList, setTimelineComponentList] = React.useState<timelineComponent[]>([] as timelineComponent[]);
    const [isDisplayLoadingMark, setDisplayLoadingMark] = React.useState<boolean>(false);
    const [operationHistoryError, setOperationHistoryError] = React.useState<string>();

    useEffect(() => {
        const fetchData = async () => {
            if (props.tenantId && props.order?.orderID) {
                let apigw: APIConnector = APIConnector.instance;
                if (timelineComponentList.length === 0) {
                    setDisplayLoadingMark(true);
                };
                await apigw.getHistoryForCustomer(props.tenantId, props.order?.orderID).then((result) => {
                    setDisplayLoadingMark(false);
                    setOperationHistoryError(undefined);
                    let timelineComponentList: timelineComponent[] = new Array();
                    for (const operationHistory of result) {
                        let timelineComponent: timelineComponent;
                        let recordType: RecordType | undefined;
                        let timelineComponentStatus: TimelineStatus = TimelineStatus.Empty;
                        let timelineComponentOperation = "";
                        let timelineComponentNewOrderInfo: OrderInfo | undefined

                        // previousDate = date;
                        if (operationHistory.tableName.endsWith("-adoms-order-flight-junction")) {
                            recordType = OperationHistoryUtil.getRecordType(operationHistory);

                            // tableがadoms-flightの場合
                            if (EventName.INSERT === operationHistory.eventName) {
                                if (recordType === RecordType.Order) {
                                    // 新規で配送依頼された場合
                                    timelineComponentStatus = TimelineStatus.Order_NewOrder;
                                    timelineComponentOperation = "配送依頼";
                                    timelineComponentNewOrderInfo = operationHistory.newItem?.body as OrderInfo;
                                };
                            } else if (EventName.MODIFY === operationHistory.eventName) {
                                // eventNameがMODIFYの場合
                                if (recordType === RecordType.Flight) {
                                    if (operationHistory.modifiedColumn?.includes("flight-status")) {
                                        const afterChangeStatus = (operationHistory.newItem?.body as FlightInfo).status;

                                        if (afterChangeStatus === "OB-" + TimelineStatus.Flight_Takeoff) {
                                            timelineComponentStatus = TimelineStatus.Flight_Takeoff;
                                            timelineComponentOperation = "ドローン離陸";
                                        } else if (afterChangeStatus === "OB-" + TimelineStatus.Flight_AfterLanding) {
                                            timelineComponentStatus = TimelineStatus.Flight_AfterLanding
                                            timelineComponentOperation = "ドローン着陸";
                                        };
                                    };
                                } else if (recordType === RecordType.Order) {
                                    if (operationHistory.modifiedColumn?.includes("order-receiveStatus")) {

                                        const afterChangeStatus = (operationHistory.newItem?.body as OrderInfo).receiveStatus;
                                        timelineComponentNewOrderInfo = operationHistory.newItem?.body as OrderInfo;

                                        if (afterChangeStatus === TimelineStatus.Order_WaitingForPickup) {
                                            timelineComponentStatus = TimelineStatus.Order_WaitingForPickup
                                            timelineComponentOperation = "配送物準備中"
                                        } else if (afterChangeStatus === TimelineStatus.Order_DepartForPort) {
                                            timelineComponentStatus = TimelineStatus.Order_DepartForPort
                                            if ((operationHistory.newItem?.body as OrderInfo).businessPartnerId === "OKINAWA_BLOOD") {
                                                timelineComponentOperation = "血液センター出発"
                                            } else {
                                                timelineComponentOperation = "出発ポートに向けて出発"
                                            };
                                        } else if (afterChangeStatus === TimelineStatus.Order_Preparation) {
                                            timelineComponentStatus = TimelineStatus.Order_Preparation
                                            timelineComponentOperation = "ドローン準備中"
                                        } else if (afterChangeStatus === TimelineStatus.Order_Loaded) {
                                            timelineComponentStatus = TimelineStatus.Order_Loaded
                                            timelineComponentOperation = "ドローン搭載済"
                                        } else if (afterChangeStatus === TimelineStatus.Order_Received) {
                                            timelineComponentStatus = TimelineStatus.Order_Received
                                            timelineComponentOperation = "配送完了"
                                        } else if (afterChangeStatus === TimelineStatus.Order_Cancel) {
                                            timelineComponentStatus = TimelineStatus.Order_Cancel
                                            timelineComponentOperation = "配送キャンセル"
                                        };
                                    };
                                };
                            };
                        };

                        const dateList = operationHistory.updateDateTime.match(/\d{4}-\d{2}-\d{2}/);
                        const date = dateList ? dateList[0] as string : undefined;
                        const timeList = operationHistory.updateDateTime.match(/\d{2}:\d{2}/);
                        const time = timeList ? timeList[0] as string : undefined;

                        timelineComponent = {
                            date: date ? date : "",
                            time: time ? time : "",
                            recordType: recordType,
                            status: timelineComponentStatus,
                            operation: timelineComponentOperation,
                            newOrderInfo: timelineComponentNewOrderInfo,
                            updateUserInfo: operationHistory.updateUserInfo
                        };
                        //暫定対応。対応していない変更履歴を画面に出力しない措置
                        if (timelineComponent.operation) {
                            timelineComponentList.push(timelineComponent);
                        };
                    };
                    setTimelineComponentList(timelineComponentList);
                }).catch((e) => {
                    console.log(e);
                    setOperationHistoryError("配送状況を取得できませんでした")
                    setDisplayLoadingMark(false);
                })
            }
        }
        fetchData();
    }, [props.order, props.tenantId]);

    const timelineIcon = (status: TimelineStatus, recordType?: RecordType) => {
        let timelineIcon;
        if (recordType === RecordType.Flight) {
            if (TimelineStatus.Flight_Takeoff === status) {
                timelineIcon = (
                    <TimelineDot color="primary">
                        <FlightTakeoffIcon />
                    </TimelineDot>
                )
            } else if (TimelineStatus.Flight_AfterLanding === status) {
                timelineIcon = (
                    <TimelineDot color="primary">
                        <FlightLandIcon />
                    </TimelineDot>
                )
            };
        } else if (recordType === RecordType.Order) {
            if (TimelineStatus.Order_NewOrder === status) {
                timelineIcon = (
                    <TimelineDot sx={{ backgroundColor: "#00695f" }}>
                        <SvgIcon>
                            <CartCheckIcon />
                        </SvgIcon>
                    </TimelineDot>
                )
            } else if (TimelineStatus.Order_WaitingForPickup === status) {
                timelineIcon = (
                    <TimelineDot sx={{ backgroundColor: "#00695f" }}>
                        <SvgIcon>
                            <PackageIcon />
                        </SvgIcon>
                    </TimelineDot>
                )
            } else if (TimelineStatus.Order_DepartForPort === status) {
                timelineIcon = (
                    <TimelineDot sx={{ backgroundColor: "#00695f" }}>
                        <EmojiTransportationIcon />
                    </TimelineDot>
                )
            } else if (TimelineStatus.Order_Preparation === status) {
                timelineIcon = (
                    <TimelineDot sx={{ backgroundColor: "#00695f" }}>
                        <SvgIcon>
                            <StoreClockIcon />
                        </SvgIcon>
                    </TimelineDot>
                )
            } else if (TimelineStatus.Order_Loaded === status) {
                timelineIcon = (
                    <TimelineDot sx={{ backgroundColor: "#00695f" }}>
                        <SvgIcon>
                            <PackageCheckedIcon />
                        </SvgIcon>
                    </TimelineDot>
                )
            } else if (TimelineStatus.Order_Received === status) {
                timelineIcon = (
                    <TimelineDot sx={{ backgroundColor: "#00695f" }}>
                        <SvgIcon>
                            <DoneIcon />
                        </SvgIcon   >
                    </TimelineDot>
                )
            } else if (TimelineStatus.Order_Cancel === status) {
                timelineIcon = (
                    <TimelineDot sx={{ backgroundColor: "#00695f" }}>
                        <CancelIcon />
                    </TimelineDot>
                )
            };
        };
        return timelineIcon;
    };

    return (
        <React.Fragment>
            {!isDisplayLoadingMark ?
                !operationHistoryError ?
                    <Timeline position="right" sx={{ paddingLeft: 0 }}>
                        {timelineComponentList.map((timelineComponent, i) => (
                            <TimelineItem>
                                <TimelineOppositeContent>
                                    <Typography variant="body2" color="inherit" sx={{ width: "36px" }}>
                                        {i == 0 || (i > 0 && JSON.stringify(timelineComponentList[i - 1].date) != JSON.stringify(timelineComponent.date))
                                            ? timelineComponent.date : null}
                                    </Typography>
                                    <Typography variant="body2" color="inherit">
                                        {timelineComponent.time}
                                    </Typography>
                                </TimelineOppositeContent >
                                <TimelineSeparator>
                                    {i == 0 ?
                                        null : <TimelineConnector />}
                                    {timelineIcon(timelineComponent.status, timelineComponent.recordType)}
                                    {i == timelineComponentList.length - 1 ?
                                        null : <TimelineConnector />}
                                </TimelineSeparator>
                                <OperationHistoryTimelineContent timelineComponent={timelineComponent} />
                            </TimelineItem>
                        ))}
                    </Timeline> :
                    <Alert severity="error">{operationHistoryError}</Alert>
                :
                <Grid textAlign={"center"}>
                    <CircularProgress color="primary" />
                </Grid>}
        </React.Fragment>
    );
}