import { Port, PortType } from "./FlightInformation/Port";
import { FlightStatus } from "./FlightInformation/FlightStatus";
import { ShipInformation } from "./ShipInformation";

/**
 * パートナー情報のバリデータ
 */
export class PartnerValidator {
    public static checkId(partnerId: string | undefined): { result: boolean, reason: string } {

        if (partnerId === undefined || partnerId === "") {
            return { result: false, reason: "\"partnerId\" is required" }
        }

        if (!RegExp(/^[A-Z0-9_]{1,16}$/).test(partnerId)) {
            return { result: false, reason: "\"partnerId\" must be matching ^[A-Z0-9]{16}$" }
        }
        return { result: true, reason: "" };
    }
}

/**
 * 地図情報に関するバリデータ
 */
export class GeoValidator {
    public static checkLat(lat: number | undefined): { result: boolean, reason: string } {
        if (lat === undefined || Number.isNaN(lat)) {
            return { result: false, reason: "\"lat\" is required" }
        }

        if (!(lat >= -90 && lat <= 90)) {
            return { result: false, reason: "\"lat\" is invalid format" }
        }
        return { result: true, reason: "" };
    }

    public static checkLon(lon: number | undefined): { result: boolean, reason: string } {
        if (lon === undefined || Number.isNaN(lon)) {
            return { result: false, reason: "\"lon\" is required" }
        }

        if (!(lon >= -180 && lon <= 180)) {
            return { result: false, reason: "\"lon\" must be between 0 and 180" }
        }
        return { result: true, reason: "" };
    }



    /**
     * 指定可能な半径の範囲
     * @param radius 
     */
    public static checkRadius(radius: number | undefined): { result: boolean, reason: string } {
        if (radius === undefined) {
            return { result: false, reason: "\"radius\" is required" }
        }

        if (!(radius >= 1 && radius <= 5000)) {
            return { result: false, reason: "\"radius\" must be between 1m and 5000m" }
        }
        return { result: true, reason: "" };
    }

}

/**
 * Dateバリデータ
 */
export class DateValidator {
    public static checkISO8601FullTimeMillisec(dateString: string | undefined): { result: boolean, reason: string } {

        if (dateString === undefined) {
            return { result: false, reason: "\"dateString\" is required" }
        }

        if (!RegExp(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}[+-]\d{2}:\d{2}/).test(dateString)) {
            return { result: false, reason: "\"dateString\" must be matching ISO8601" }
        }
        return { result: true, reason: "" };
    }

    public static checkISO8601Date(dateString: string | undefined): { result: boolean, reason: string } {
        if (dateString === undefined) {
            return { result: false, reason: "\"dateString\" is required" }
        }

        if (!RegExp(/^\d{4}-\d{2}-\d{2}$/).test(dateString)) {
            return { result: false, reason: "\"dateString\" must be matching ISO8601" }
        }

        return { result: true, reason: "" };
    }

    /**
     * 引数で与えられた文字列がdatetime-localのTextfieldの書式を満たしているか確認する
     * @param dateString 日付を表す文字列
     * @returns datetime-localのTextfieldの書式を満たしているか
     */
    public static checkISO8601DateAndSimpleTime(dateString: string): boolean {
        return RegExp(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/).test(dateString);
    }


    /**
     * STD/STAがフォーマット通りであるかを確認する
     * @param dateString 
     * @returns 
     */
    public static checkXTDXTA(timeString: string | undefined): { result: boolean, reason: string } {
        if (timeString === undefined) {
            return { result: false, reason: "\"dateString\" is required" }
        }

        if (!/^(2[0-3])|([01][0-9])\:[0-5][0-9]$/g.test(timeString)) {
            return { result: false, reason: "\"dateString\" must be matching ISO8601" }
        }

        return { result: true, reason: "" };
    }

    /**
     * STD/STA(秒単位)がフォーマット通りであるかを確認する
     * @param dateString 
     * @returns 
     */
    public static checkXTDXTASeconds(timeString: string | undefined): { result: boolean, reason: string } {
        if (timeString === undefined) {
            return { result: false, reason: "\"dateString\" is required" }
        }

        if (!/^(2[0-3])|([01][0-9])\:[0-5][0-9]\:[0-5][0-9]$/g.test(timeString)) {
            return { result: false, reason: "\"dateString\" must be matching ISO8601" }
        }

        return { result: true, reason: "" };
    }

    /**
     * 与えられた引数が同じ日付であるかを確認する
     * @param date1　日付1
     * @param date2　日付2
     * @returns 同じ日付であるか
     */
    public static isSameDay(date1: Date, date2: Date): { result: boolean, reason: string } {
        const result = date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate();
        return { result: result, reason: "" };
    }
}

export class FlightInfoValidator {
    /**
     * フライトIDとして渡された文字列が要件を満たすかをチェックする関数
     * @param flightId 
     * @returns 英数字8桁で構成されているかどうか
     */
    public static flightIdValidator(flightId: string): boolean {
        return /^[0-9A-Z]{8}$/g.test(flightId);
    }

    /**
    * 渡された文字列がFlightStatus型に該当するかどうかチェックする関数
    * @param item FlightStatusにあたるステータス名
    * @returns 当該文字列がFlightStatus型に該当するかどうか
    */
    public static flightStatusValidatorFromString(flightStatusString: string): flightStatusString is FlightStatus {
        const statusList: FlightStatus[] = [
            FlightStatus.Empty,
            FlightStatus.OBStandby,
            FlightStatus.OBBeforePreparation,
            FlightStatus.OBPreparation,
            FlightStatus.OBBeforeTakeoff,
            FlightStatus.OBTakeoff,
            FlightStatus.OBInFlightClimb,
            FlightStatus.OBInFlightCruise,
            FlightStatus.OBInFlightDescent,
            FlightStatus.OBAfterLanding,
            FlightStatus.RTStandby,
            FlightStatus.RTBeforePreparation,
            FlightStatus.RTPreparation,
            FlightStatus.RTBeforeTakeoff,
            FlightStatus.RTTakeoff,
            FlightStatus.RTInFlightClimb,
            FlightStatus.RTInFlightCruise,
            FlightStatus.RTInFlightDescent,
            FlightStatus.RTAfterLanding,
            FlightStatus.Completed,
            FlightStatus.Reset,
            FlightStatus.Irregular,
            FlightStatus.Canceled
        ]
        const statusSet = new Set<FlightStatus>(statusList);
        return statusSet.has(flightStatusString as FlightStatus);
    }
}

export class OrderInfoValidator {
    /**
     * orderIDとして渡された文字列が要件を満たすかをチェックする関数
     * @param orderId 
     * @returns 英数字8桁で構成されているかどうか
     */
    public static orderIdValidator(orderId: string): boolean {
        return /^\d{8}$/g.test(orderId);
    };

    /**
     * 当該文字列がbase64で、尚且つデータ部がjpg・pngのいずれかであるかを確認する関数
     * チェックする内容は以下の通り
     * ・ヘッダーが書式に則っているか
     * ・データ部の文字数が4の倍数かどうか
     * ・データ部は使用可能な文字だけが使用されているかどうか
     * @returns 当該文字列がbase64で、尚且つデータ部がjpg・pngのいずれかであるかどうか
     */
    public static receiptJpegPngImageBase64Validator(receiptImageBase64: string): boolean {
        /*
            ヘッダー部のバリデーション及びデータ部の文字チェック用正規表現
            ヘッダー部（data:～base64,）は書式が決まっているため、
            その書式に沿っているかをチェックする
            また、データ部は、先頭にjpegまたはpng特有の文字列が存在するか、
            base64で認められている文字のみが使用されているかを確認する
        */
        let regex = /^data:image\/(jpeg|png);base64,[iVBOR|/9j/4][\w+\/=]+$/;

        //ヘッダー部の内容と、データ部に使用されている文字がルールに沿っているか確認
        if (regex.test(receiptImageBase64)) {
            //データ部の文字数取得
            let dataPartLength = receiptImageBase64.replace(/^data:image\/(jpeg|png);base64,/, '').length;
            /*
                データ部の文字数が4の倍数であれば、
                base64で符号化された画像として取り扱う
            */
            return dataPartLength % 4 === 0;
        }

        //if文の時点でルールに沿っていない場合はfalseを返す
        return false;
    };
}

// export class DroneValidator {
//     /**
//     * 渡された文字列がdroneStatus型に該当するかどうかチェックする関数
//     * @param item droneStatusにあたるステータス名
//     * @returns 当該文字列がdroneStatus型に該当するかどうか
//     */
//     public static isDroneStatusFromString(droneStatusString: string): droneStatusString is DroneStatus {
//         const statusList: DroneStatus[] = [
//             DroneStatus.Empty,
//             DroneStatus.Docking,
//             DroneStatus.Accepting,
//             DroneStatus.Waiting,
//             DroneStatus.Preparing,
//             DroneStatus.Flighting,
//             DroneStatus.Returning,
//             DroneStatus.Canceled
//         ]
//         const statusSet = new Set<DroneStatus>(statusList);
//         return statusSet.has(droneStatusString as DroneStatus);
//     }
// }

/**
 * ポートバリデータ
 */
export class PortValidator {

    /**
     * 必須項目存在チェック
     * true:必須項目設定あり false:必須項目設定なし
     * @param value
     */
    public static existRequiredItems(port: Port): string[] {
        let portNotExistItemList: string[] = [];

        if (!port.sortkey) {
            portNotExistItemList.push("sortkey");
        }
        if (!port.name) {
            portNotExistItemList.push("name");
        }
        if (!port.shortName) {
            portNotExistItemList.push("shortName");
        }
        if (!port.businessPartnerId) {
            portNotExistItemList.push("partnerId");
        }
        if (!port.portType) {
            portNotExistItemList.push("portType");
        }
        if (!port.address) {
            portNotExistItemList.push("address");
        }
        if (!port.longitude) {
            portNotExistItemList.push("longitude");
        }
        if (!port.latitude) {
            portNotExistItemList.push("latitude");
        }
        if (!port.zipcode) {
            portNotExistItemList.push("zipcode");
        }
        if (!port.prefecture) {
            portNotExistItemList.push("prefecture");
        }
        if (!port.city) {
            portNotExistItemList.push("city");
        }

        return portNotExistItemList;
    }

    /**
     * 名前フォーマットチェック（ひらがな、カタカナ、漢字、半角大文字小文字英字・数字許容）
     * @param value
     */
    public static isNameFormat(value: string): boolean {

        if (!value.match(/^[\p{scx=Hiragana}\p{scx=Katakana}\p{scx=Han}a-zA-Z0-9ー]+$/u)) {
            return false;
        }
        return true;
    }

    /**
     * ポートID書式チェック（8桁、かつ半角大文字英字・数字）
     * @param value
     */
    public static isPortIdFormat(value: string): boolean {
        if (!value.match(/^[A-Z0-9]{8}$/)) {
            return false;
        }
        return true;
    }

    /**
     * スポットIDチェック（半角大文字英字・数字）
     * @param value
     */
    public static isSpotIdFormat(value: string): boolean {

        if (!value.match(/^[A-Z0-9]+$/)) {
            return false;
        }
        return true;
    }

    /**
     * 市区町村名チェック（ひらがな、カタカナ、漢字のみ許可）
     * @param value
     */
    public static isCityFormat(value: string): boolean {
        if (!value.match(/^[\p{scx=Hiragana}\p{scx=Katakana}\p{scx=Han}ー]+$/u)) {
            return false;
        }
        return true;
    }

    /**
     * 緯度経度チェック（半角数字、符号あり小数、+, – 許容)
     * @param value
     */
    public static isLatLonFormat(value: string): boolean {

        if (!value.match(/^[+,-]?([1-9]\d*|0)(\.\d+)?$/)) {
            return false;
        }
        return true;
    }

    /**
     * パートナーIDチェック（半角大文字英字・数字）
     * @param value
     */
    public static isPartnerIdFormat(value: string): boolean {

        if (!value.match(/^[A-Z0-9_]+$/)) {
            return false;
        }
        return true;
    }

    /**
     * ポート管理会社チェック（半角大文字英字）
     * @param value
     */
    public static isPortManagementCompanyFormat(value: string): boolean {

        if (!value.match(/^[A-Z]+$/)) {
            return false;
        }
        return true;
    }

    /**
     * 電話番号チェック（半角数字）
     * @param value
     */
    public static isPhoneNumFormat(value: string): boolean {

        if (!value.match(/^[0-9]+$/)) {
            return false;
        }
        return true;
    }

    /**
     * メールアドレスチェック
     * @param value
     */
    public static isMailAddress(value: string): boolean {

        if (!value.match(/^[A-Za-z0-9]{1}[A-Za-z0-9+_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/)) {
            return false;
        }
        return true;
    }

    /**
     * 郵便番号チェック（半角数字7桁）
     * @param value
     */
    public static isZipCodeFormat(value: string): boolean {

        if (!value.match(/^[0-9]{7}$/)) {
            return false;
        }
        return true;
    }

    /**
     * ソートキーチェック（半角数字）
     * @param value
     */
    public static isSortkeyFormat(value: string): boolean {

        if (!value.match(/^[0-9]+$/)) {
            return false;
        }
        return true;
    }

    /**
    * 渡された文字列がportType型に該当するかどうかチェックする関数
    * @param item portTypeにあたるステータス名
    * @returns 当該文字列がportType型に該当するかどうか
    */
    public static isPortTypeFromString(portTypeString: string): portTypeString is PortType {
        const statusList: PortType[] = [
            PortType.sender,
            PortType.receiver,
            PortType.both
        ]
        const statusSet = new Set<PortType>(statusList);
        return statusSet.has(portTypeString as PortType);
    }
}
export class AisValidator {
    /**
     * 必須項目存在チェック
     * true:必須項目設定あり false:必須項目設定なし
     * @param value
     */
    public static existRequiredAisItems(aisInfo: ShipInformation): string[] {
        let aisNotExistItemList: string[] = [];

        if (!aisInfo.userId) {
            aisNotExistItemList.push("userId");
        }
        if (!aisInfo.date) {
            aisNotExistItemList.push("date");
        }
        if (!aisInfo.timeStamp) {
            aisNotExistItemList.push("timeStamp");
        }
        if (aisInfo.messageType == null) {
            aisNotExistItemList.push("messageType");
        }
        if (aisInfo.speedOverGround == null) {
            aisNotExistItemList.push("speedOverGround");
        }
        if (aisInfo.positionAccuracy == null) {
            aisNotExistItemList.push("positionAccuracy");
        }
        if (aisInfo.latitude == null) {
            aisNotExistItemList.push("latitude");
        }
        if (aisInfo.longitude == null) {
            aisNotExistItemList.push("longitude");
        }
        if (aisInfo.courseOverGround == null) {
            aisNotExistItemList.push("courseOverGround");
        }
        if (aisInfo.trueHeading == null) {
            aisNotExistItemList.push("trueHeading");
        }
        if (!aisInfo.deviceID) {
            aisNotExistItemList.push("deviceID");
        }
        console.log(aisNotExistItemList)
        return aisNotExistItemList;
    }
}

export class UserValidator {
    /**
     * メールアドレスチェック
     * @param value
     */
    public static isMailAddress(value: string): boolean {

        if (!value.match(/^[A-Za-z0-9]{1}[A-Za-z0-9+_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/)) {
            return false;
        }
        return true;
    }
}
